<template >
  <b-container fluid class="container">
    <loading :active.sync="isWorkingAdmin" :is-full-page="true"></loading>
    <b-row align="center" >
      <b-col style="cursor:pointer;">
        <h4 style="color:white; margin-bottom:15px; margin-top:10px;">
          <b-icon icon="newspaper"/> Mis Trámites <b-icon font-scale=".8" icon="arrow-repeat" @click="getTramitesAdmin()"/>
        </h4>
      </b-col>
    </b-row>
    
    <b-row align-h="center">
      <b-col xl="6" lg="6" md="9" cols="12" class="margin-sup-card" v-for="categoria in categorias" :key="categoria.idCategoria">
        <transition name="slide-vertical">
          <b-card class="card-tramite-admin c-shadow-sm" v-if="isShowCardsAdmin">
            <b-row>
              <div class="img-supposition-squad" >
                <img height="30px;" :src="'../imgs/' + categoria.icon"/>
              </div>
            </b-row>
            <b-row align="center"  style="min-height:40px; margin-top:45px;">
              <b-col cols="12">
                <b-card-title class="title-card">
                  {{categoria.nombreServiceInfo.toLowerCase()}}
                </b-card-title>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" style="margin-right:30px; margin-bottom:15px;" class="d-block d-sm-none">
                <vc-donut
                  foreground="#c7c7c7"
                  :size="50" unit="%" :thickness="30"
                  :sections="[{id:categoria.idServiceInfo, label: 'Iniciados', value: categoria.iniciados, color: '#126EE5' }, { id:categoria.idServiceInfo,  label: 'Por revisar', value: categoria.porRevisar, color: '#099200' }, {id:categoria.idServiceInfo, label: 'Por pagar', value: categoria.pPago, color: '#E05F00' }, {id:categoria.idServiceInfo, label: 'Pagados', value: categoria.pagados, color: '#00BEE4' }, {id:categoria.idServiceInfo, label: 'Por entregar', value: categoria.pEntregar, color: '#9834eb' }]" 
                  :total="categoria.pendientes<=10?10:categoria.pendientes"
                  :start-angle="0" :auto-adjust-text-size="true">
                  <h2>{{categoria.pendientes}}</h2>
                  <p v-if="categoria.pendientes!=1"><b style="font-size:90%;">Trámites</b></p>
                  <p v-if="categoria.pendientes==1"><b style="font-size:90%;">Trámite</b></p>
                  <p v-if="categoria.pendientes!=1" style="margin-top:-20px;"><b style="font-size:90%;">Pendientes</b></p>
                  <p v-if="categoria.pendientes==1" style="margin-top:-20px;"><b style="font-size:90%;">Pendiente</b></p>
                </vc-donut>
              </b-col>
              <b-col style="margin-left:0px;">
                <b-row align-h="center">
                  <b-col cols="6" sm="12"><b-link class="iniciado" @click="showRevisionTramite(categoria.idServiceInfo, 'INICIADO')"><b style="font-size:80%"><b-icon icon="file-text"/> Iniciados: {{categoria.iniciados}}</b></b-link></b-col>
                  <b-col cols="6" sm="12"><b-link class="por-revisar" @click="showRevisionTramite(categoria.idServiceInfo, 'POR REVISAR')"><b style="font-size:80%"><b-icon icon="file-text"/> Por revisar: {{categoria.porRevisar}}</b></b-link></b-col>
                  <b-col cols="6" sm="12"><b-link class="pendiente-pago" @click="showRevisionTramite(categoria.idServiceInfo, 'PENDIENTE PAGO')"><b style="font-size:80%"><b-icon icon="file-text"/> Por pagar: {{categoria.pPago}}</b></b-link></b-col>
                  <b-col cols="6" sm="12"><b-link class="pagado" @click="showRevisionTramite(categoria.idServiceInfo, 'PAGADO')"><b style="font-size:80%"><b-icon icon="file-text"/> Pagados: {{categoria.pagados}}</b></b-link></b-col>
                  <b-col cols="6" sm="12"><b-link class="por-entregar" @click="showRevisionTramite(categoria.idServiceInfo, 'POR ENTREGAR')"><b style="font-size:80%"><b-icon icon="file-text"/> Por entregar: {{categoria.pEntregar}}</b></b-link></b-col>
                  <b-col cols="6" sm="12">
                    <b-button size="sm" v-b-toggle.collapse-c-admin style="background-color:white; color:gray; border:none; box-shadow:none; margin-left:-7px;">
                      <span class="when-open">Ocultar <b-icon font-scale=".9" shift-v="2" icon="caret-up-fill"/></span> 
                      <span class="when-closed">Mostrar más <b-icon font-scale=".9" shift-v="2" icon="caret-down-fill"/></span>
                    </b-button>
                    <b-collapse id="collapse-c-admin" >
                      <b-row>
                        <b-col cols="12" sm="12"><b-link class="concluido" @click="showRevisionTramite(categoria.idServiceInfo, 'CONCLUIDO')"><b style="font-size:80%"><b-icon icon="file-text"/> Concluidos: {{categoria.concluidos}}</b></b-link></b-col>
                        <b-col cols="12" sm="12"><b-link class="cancelado" @click="showRevisionTramite(categoria.idServiceInfo, 'CANCELADO')"><b style="font-size:80%"><b-icon icon="file-text"/> Cancelados: {{categoria.cancelados}}</b></b-link></b-col>
                        <b-col cols="12" sm="12"><p><b style="font-size:80%"><b-icon icon="file-text"/> Recibidos: {{categoria.total}}</b></p></b-col>
                      </b-row>
                    </b-collapse>    
                  </b-col>
                </b-row>
                
              </b-col>
              <b-col style="margin-right:70px; " class="d-none d-sm-block">
                <vc-donut
                  foreground="#c7c7c7"
                  :size="85" unit="%" :thickness="30"
                  :sections="[{id:categoria.idServiceInfo, label: 'Iniciados', value: categoria.iniciados, color: '#126EE5' }, { id:categoria.idServiceInfo,  label: 'Por revisar', value: categoria.porRevisar, color: '#099200' }, {id:categoria.idServiceInfo, label: 'Por pagar', value: categoria.pPago, color: '#E05F00' }, {id:categoria.idServiceInfo, label: 'Pagados', value: categoria.pagados, color: '#00BEE4' }, {id:categoria.idServiceInfo, label: 'Por entregar', value: categoria.pEntregar, color: '#9834eb' }]" 
                  :total="categoria.pendientes<=10?10:categoria.pendientes"
                  :start-angle="0" :auto-adjust-text-size="true"
                  @section-click="showRevisionChart">
                  <h1>{{categoria.pendientes}}</h1>
                  <p v-if="categoria.pendientes!=1"><b style="font-size:90%;">Trámites</b></p>
                  <p v-if="categoria.pendientes==1"><b style="font-size:90%;">Trámite</b></p>
                  <p v-if="categoria.pendientes!=1" style="margin-top:-25px;"><b style="font-size:90%;">Pendientes</b></p>
                  <p v-if="categoria.pendientes==1" style="margin-top:-25px;"><b style="font-size:90%;">Pendiente</b></p>
                </vc-donut>
              </b-col>
            </b-row>
            
            <div class="error">
              <h6 v-if="categoria.status=='INACTIVO'">Este trámite esta inactivo y ya no se muestra en el catálogo de trámites del usuario</h6>
            </div>
          </b-card>
        </transition>
      </b-col>
    </b-row>
    <transition name="slide-vertical">
      <b-card class=" c-shadow-sm" v-if="isShowCardsAdmin">
        <b-form-checkbox v-model="busquedaByCurp"> Buscar trámite por CURP</b-form-checkbox>
        <b-row  align-v="start">
          <b-col cols="12" md="4">
            <b-form-group v-if="busquedaByCurp">
              <b-form-input size="sm" style="text-transform: uppercase" :state="!$v.curpContribuyente.$invalid" v-model="curpContribuyente"></b-form-input>
              <div class="error" v-if="!$v.curpContribuyente.minLength">El CURP es muy corto</div>
              <div class="error" v-if="!$v.curpContribuyente.maxLength">El CURP es demasiado largo</div>
              <div class="error" v-if="!$v.curpContribuyente.required">Ingrese el curp del usuario</div>
            </b-form-group>
          </b-col>
          <b-col v-if="busquedaByCurp" cols="12" md="2">
            <b-button size="sm" block :disabled="$v.curpContribuyente.$invalid" @click="buscarTramite">Buscar</b-button>
          </b-col>
        </b-row>
      </b-card>
    </transition>

    <!--modals-->
    <b-modal v-model="isShowModalTableTramites" size="lg" title="Trámites" hide-footer>
      <panelTableTramites/>
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { tramitesAdmin, buscarTramitesContrib, currentTramitesAdmin } from '@/axios-tools';
  import { minLength, maxLength, required } from 'vuelidate/lib/validators';
  import panelTableTramites from './panelTableTramites.vue';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
  import Donut from 'vue-css-donut-chart';
  import 'vue-css-donut-chart/dist/vcdonut.css';

  Vue.use(Donut);

  export default {
    computed: {
      ...mapState({
        token: state => state.admin.token,
        categorias: state => state.admin.categorias,
        isWorkingAdmin: state => state.admin.isWorkingAdmin,
        perfilAdmin:state => state.admin.perfilAdmin,
        isShowCardsAdmin:state => state.admin.isShowCardsAdmin
      }),
      isShowModalTableTramites:{
        get: function() {
          return this.$store.state.admin.isShowModalTableTramites;
        },
        set: function(newIsShowModalTableTramites) {
          this.$store.commit("updateIsShowModalTableTramites", newIsShowModalTableTramites);
        }
      },
    },
    components: {
      panelTableTramites,
      Loading
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        curpContribuyente:"",
        busquedaByCurp:false,
      }
    },
    created: function () {
      this.getTramitesAdmin();
    },
    methods: {
      buscarTramite(){
        if(!this.$v.curpContribuyente.$invalid){
          buscarTramitesContrib(this.$store, this.cliente, this.token, this.curpContribuyente, this.$router);
          this.$store.commit("updateIsBusquedaByCurp", true);
        }
      },
      showRevisionTramite(idServiceInfo, tipo){
        this.$store.commit("updateIsBusquedaByCurp", false);
        currentTramitesAdmin(this.cliente, this.token, tipo, idServiceInfo, this.$router, this.$store, true);
        this.$store.commit("updateIsShowCardsAdmin", false);
      },
      getTramitesAdmin(){
        tramitesAdmin(this.cliente, this.token,this.$store, this.$router);
      },
      showRevisionChart(section) {
        var tipo =section.label=='Iniciados'? 'INICIADO':
                  section.label=='Por revisar'? 'POR REVISAR':
                  section.label=='Por pagar'? 'PENDIENTE PAGO':
                  section.label=='Pagados'? 'PAGADO':
                  section.label=='Por entregar'? 'POR ENTREGAR': null;
        this.showRevisionTramite(section.id, tipo);
      }
    },
    validations: {
      curpContribuyente:{
        minLength: minLength(18),
        maxLength: maxLength(18),
        required
      }
    }
  }
</script>
<style>
.margin-sup-card{
  margin-top:40px;
}
.img-supposition-squad{
  background: linear-gradient(90deg, rgba(255,155,15,1) 0%, rgba(255,155,15,1) 0%, rgba(204,113,0,1) 100%);
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  border-radius: 25px;
  font-size:20px;
  padding-top: 15px;
  width: 100px;
  height: 65px;
  position:absolute;
  top:-25px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}
.title-card{
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
}
.title-page{
  font-family: 'Asap-MediumItalic';
  margin-top:10px;
}
.card-tramite-admin{
  border-radius: 15px;
  margin-bottom:25px;
  min-height:300px;
}
</style>